import cn from 'classnames';

import Typography from '@/components/base/elements/Typography/Typography';

import {wrapperStyles, headerStyles} from './style';

type TopicHeroSectionProps = {
  children: string;
  subTitle?: string;
  className?: string;
};

const TopicHeroSection = ({
  children,
  subTitle,
  className,
}: TopicHeroSectionProps) => {
  return (
    <div className={cn(wrapperStyles, className)}>
      <div className="container">
        <Typography as="h1" className={headerStyles}>
          {children}
        </Typography>
        {subTitle && (
          <Typography
            className="tablet:text-center text-sm mt-[10px]"
            isRichtext
          >
            {subTitle}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default TopicHeroSection;
